@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Caveat:wght@700&family=Lobster&family=Pacifico&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
}

.white_bg {
  position: absolute;
  background: #fff;
  box-shadow: 0 0 40px #421476;
  height: 200%;
  width: 200px;
  opacity: 1;
  left: 50%;
}

